var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "aptamer-favorite-container" }, [
    _c(
      "div",
      { staticClass: "item-list" },
      [
        _vm._l(_vm.records, function (item, i) {
          return _c("div", { key: i, staticClass: "item-container" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "location" }, [
                _c(
                  "a",
                  {
                    staticClass: "entry entry-title",
                    attrs: { href: `/clinical/${item.id}` },
                  },
                  [_vm._v(" " + _vm._s(item.title) + " ")]
                ),
                _c("div", { staticClass: "entry" }, [
                  _c("div", [
                    _vm._v(" 适应症："),
                    _c("span", { staticStyle: { color: "#3463ea" } }, [
                      _vm._v(_vm._s(item.conditions)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "entry" }, [
                  _c("div", { staticStyle: { width: "11%" } }, [
                    _vm._v(" 招募状态： "),
                  ]),
                  _c("div", [
                    _c("span", { staticStyle: { color: "#3463ea" } }, [
                      _vm._v(_vm._s(item.recruitmentStatus)),
                    ]),
                  ]),
                  _c("div", { staticStyle: { "margin-left": "16px" } }, [
                    _vm._v(" 开始日期："),
                    _c("span", { staticStyle: { color: "#3463ea" } }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("dateFormat")(
                            item.studyStartDate,
                            "YYYY-MM-DD"
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "entry" }, [
                  _c("div", { staticStyle: { width: "11%" } }, [
                    _vm._v("研究单位："),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "textOmitted",
                      staticStyle: { width: "89%" },
                    },
                    [_vm._v(" " + _vm._s(item.organization) + " ")]
                  ),
                ]),
              ]),
            ]),
          ])
        }),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              attrs: {
                id: "pagination",
                "current-page": _vm.pager.index,
                "page-sizes": [10, 25, 50],
                "page-size": _vm.pager.size,
                layout: "prev, pager, next, jumper, sizes",
                total: _vm.total,
              },
              on: { "current-change": _vm.query, "size-change": _vm.query },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }