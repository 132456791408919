import { render, staticRenderFns } from "./clinical.vue?vue&type=template&id=5837b28a&scoped=true"
import script from "./clinical.vue?vue&type=script&lang=js"
export * from "./clinical.vue?vue&type=script&lang=js"
import style0 from "./clinical.vue?vue&type=style&index=0&id=5837b28a&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5837b28a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\s\\adb\\AptamerDataBase\\ClientApp\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5837b28a')) {
      api.createRecord('5837b28a', component.options)
    } else {
      api.reload('5837b28a', component.options)
    }
    module.hot.accept("./clinical.vue?vue&type=template&id=5837b28a&scoped=true", function () {
      api.rerender('5837b28a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user/favorites/components/clinical.vue"
export default component.exports